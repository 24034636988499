#AdminPage {
  width: 100%;
  padding: 20px;
}

.title-breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#container {
  width: 100%;
  padding: 10px;
  background-color: white;
}

.client-group {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.client-or-group {
  max-width: 400px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.client-divider {
  margin-bottom: 5px !important;
}
.ios-buyer-field {
  width: 240px !important;
}
