body {
  background: #F5F6F7;
  margin: 0;
  padding: 0;
  /* padding: 100px 20px 0 20px; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,
#root > div {
  height: 100%;
}

.top-back-btn{
  margin: 0.5rem 0 !important;
  border: none !important;
  box-shadow: none !important;
}
.cancel-btn{
  margin-left: 0.7rem !important;
}
.status-switch{
  display: flex;
  align-items: center;
}
.status-label{
  margin-left: 0.7rem;
}
.cp{
  cursor: pointer
}

.ui.inverted.dimmer{
 position: fixed
}
