#TabDataEntry{
  display: flex;
  overflow: hidden;
}

#main-container {
  overflow: hidden;
  flex:1
}

#main-container span.notification{
  color: green;
}

#overflowing-container {
  /* overflow: hidden; */
  overflow-y: auto;
  /* height:800px !important; */
  position:relative;
  z-index: 0;

}

#bottom-buttons {
  display: flex;
  justify-content: space-between;
}

#bottom-buttons button {
  margin: 0 5px;
}

.client-actual-loader{
  margin-top: 10rem !important;
}
