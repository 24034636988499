#year-filter {
  padding: 12px 0px;
}

#year-filter .selection{
  border: solid 1px;
  min-width: 7em;
}

#year-filter span{
  padding-right: 5px;
}

.yearDropdown {
  opacity: 1;
  z-index: 1;
}
