#filter-summary {
  text-align: right;
}

.bold-title {
  font-weight: bold;
}

.top-padding {
  padding-top: 32px !important;
}
