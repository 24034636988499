#HomePage {
  width: 100%;
  padding: 20px;
}

.title-breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#container {
  width: 100%;
  padding: 20px;
  background-color: white;
}
