#OMGNavigation {
  margin-bottom: 20px;
}

.sub-omni-nav {
  height: 25px;
  color: #CCCCCC; /* needs an omni name in semantic ui styling */
  font-size: 13px;
  line-height: 25px;
}

.sub-omni-nav .app-name {
  margin-left: 100px;

}

.sub-omni-nav .other-links {
  position: absolute;
  right: 50px;
  font-weight: bold;
}

.omni-dropdown-item{
  color: #252937 !important;
}
