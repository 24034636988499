#filter-sidebar {
  min-width: 250px;
  width: 250px;
  margin-right: 20px;
  padding-right: 25px;
  height: 570px;
}

#filter-sidebar.hidden {
  margin-right: -210px;
  transform: translateX(-230px);
}

#toggle-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 5px;
  height: 100%;
  background-color: #4A4E59;
  writing-mode: vertical-rl;
  cursor: pointer;
}

.filter-text {
  display: inline-block;
  transform: rotate(180deg);
}

#toggle-sidebar:focus {
  outline: none;
  background-color: #c5d0d5;
}

#toggle-sidebar i {
  margin: 0 0 10px 0;
}

#toggle-sidebar:focus {
  outline: none;
  background-color: #c5d0d5;
}

#toggle-sidebar:focus i {
  outline: none;
  color: #252937;
}

#toggle-sidebar:focus span {
  outline: none;
  color: #252937;
}

#DataEntryFiltersSidebar .ui.dropdown {
  margin-bottom: 13px;
  white-space: nowrap;
  height: 30px;
}

#DataEntryFiltersSidebar .ui.dropdown:before {
  content: '';
  padding-right: 20px;
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(to right, transparent, white 20px);
}

#DataEntryFiltersSidebar .ui.dropdown .text {
  width: 100%;
  overflow: hidden;
}

@media only screen and (min-width: 1920px) {
  #DataEntryFiltersSidebar .ui.selection.dropdown .menu {
    max-height: 16.371429rem;
  }
}

