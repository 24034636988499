#OMGBreadcrumb {
  display: flex;
  align-items: baseline;
}

#OMGBreadcrumb b {
  margin-right: 10px;
}

#OMGBreadcrumb .dropdown:focus {
  border: 1px solid #96c8da;
}
