#PivotTable {
  /* position: relative; */
  margin-bottom: 20px;
}

#PivotTable #table-container {
  overflow: auto;
  /* height: 800px; */
  min-height: 400px;
  max-height:800px;
}

#PivotTable #table-container table.ui.table {
  table-layout: fixed;
  border: 1px solid grey;
  border-collapse: collapse;
  /* box-sizing: border-box; */
}

#PivotTable #table-container table.ui.table th,
#PivotTable #table-container table.ui.table td {
  height: 35px;
  padding: 0 10px;
  overflow: hidden;
  color: black;
  box-sizing: border-box;
}

#PivotTable #table-container table.ui.table th.supplier-cell,
#PivotTable #table-container table.ui.table td.supplier-cell,
#PivotTable #table-container table.ui.table td.monthly-total-header {
  width: 300px !important;
  overflow-x: hidden;
  padding: 7px 10px;
  margin-top: 1px;
  border-collapse: collapse;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  white-space: nowrap;
}

#PivotTable #table-container table.ui.table td.supplier-cell:after {
  content: '';
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  /* background: linear-gradient(to right, transparent, white 40px); */
}

#PivotTable #table-container table.ui.table tr:first-child th.supplier-cell {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  margin-top: -1px;
  height: 36px;
}

#PivotTable #table-container table.ui.table tr:last-child td.supplier-cell {
  border-bottom: 1px solid grey;
}

#PivotTable thead tr:first-child th {
  background-color: #dce7ec;
  border-bottom: 2px solid grey;
}

#PivotTable thead tr:last-child th {
  background-color: white;
  border-bottom: 1px solid grey;
  text-align: center;
}

#PivotTable th.month-col {
  width: 450px;
  border-left: 2px solid grey;
  background-color: #dce7ec !important;
}

th.month-col > div.checkbox {
  float: right;
}

#PivotTable .live-forecast-cell {
  border-left: 2px solid grey;
}

#PivotTable .yearly-total-header {
  color: white !important;
  background-color: #4f5f67 !important;
  width: 300px !important;
  text-align: center;
}

#PivotTable .yearly-total-cell {
  border-left: 2px solid grey;
  font-weight: bold;
  text-align: right;
  background-color: #dce7ec !important;
}

#PivotTable .monthly-total-header {
  font-weight: bold;
  color: white !important;
  background-color: #4f5f67 !important;
  text-align: center;
}

#PivotTable .monthly-total-cell {
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  font-weight: bold;
  text-align: right;
  background-color: #dce7ec !important;
}

#PivotTable p.finalised {
  display: inline;
  color: #196c9c;
}

#table-container table tr td:first-child {
  position: sticky;
  left: 0;
  top: 0;
  /* background-color: #dbeaf3; */
  z-index: 1000;
  background-color: white;

  /* border-right: 1px solid grey; */
}

#table-container table thead tr > th:first-child {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #DCE7EC;
  z-index: 1000;
}

#table-container table th {
  position: sticky;
  top: 0;
  z-index: 600;
  background-color: #dce7ec;
  text-align: left !important;
}

#PivotTable .header-two{
  font-weight: bold;
  border-bottom: 1px solid grey;
  text-align: center;
}

#table-container table{
  border-collapse: separate !important; /* Don't collapse */
}

#PivotTable .border-less-cell input{
  border:none;
}
