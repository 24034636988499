.user-agency {
  background-color: #e3eced;
  min-height: 250px;
  padding: 10px;
  max-width: 300px !important;
  argin-left: 0 !important;
}

.user-label {
  font-weight: bold !important;
  margin-bottom: 5px;
}

.admin-options-button-group {
  margin-top: 5px;
}

.admin-form-field {
  margin-top: 40px;
}
.user-agency-ln {
  margin-top: 0px;
  margin-bottom: 50px;
}

.user-email {
  margin-top: 40px;
  margin-bottom: 0px;
}

.user-submit-btn {
  margin-top: 50px !important;
  margin-bottom: 30px !important;
}
