#LoginPage {
  height: 100%;
}

#LoginPage .ui.grid {
  height: 100%;
}

#LoginPage .ui.grid .column {
  max-width: 450px;
}
