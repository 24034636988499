.ui.table td input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

/*** Supplier Cell ***/
.ui.table .supplier-cell span {
  cursor: pointer;
}

.ui.table .supplier-cell .ui.loader {
  margin-right: 6px;
}

.ui.table .supplier-cell span:focus {
  outline: none;
}

.ui.table .supplier-cell.padding-Saleshouse {
  padding-left: 10px !important;
}

.ui.table .supplier-cell.padding-Folio {
  padding-left: 25px !important;
}

.ui.table .supplier-cell.padding-Supplier {
  padding-left: 60px !important;
}

/*** Actuals Cell ***/
.ui.table .actuals-cell {
  text-align: right;
  background-color: #DCE7EC !important;
  border-left: 1px solid grey;
}

/*** Live Forecast Cell ***/
.ui.table .live-forecast-cell {
  border-left: 2px solid grey;
  font-weight: bold;
  text-align: right;
}

.ui.table .live-forecast-cell input {
  width: 100%;
  height: 27px;
  border: 1px solid lightgrey;
  padding: 0 5px;
  background-color: white;
  text-align: right;
}

.ui.table .live-forecast-cell .sum input {
  width: 100%;
  height: 27px;
  border: 1px solid rgb(22, 21, 21);
  padding: 0 5px;
  background-color: lightgrey;
  text-align: right;
}

.ui.table .live-forecast-cell .Saleshouse input {
  border: 3px solid lightgrey;
}

/*** vs. Forecast Cell ***/
.ui.table .vs-forecast-cell {
  background-color: #DCE7EC !important;
  border-left: 1px solid grey;
  text-align: right;
}

#PivotTable .positive {
  background-color: #B1DD7F !important;
  color: black !important;
}
#PivotTable .positive:after {
  content: '●';
  color: #76C200 !important;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 20px;
  padding-left: 3px;
}

#PivotTable .warning {
  background-color: #FAD291 !important;
  color: black !important;
  text-align: right;
}
#PivotTable .warning:after {
  content: '■';
  color: #F3AF00 !important;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 20px;
  padding-left: 3px;
}

#PivotTable .negative-danger {
  background-color: #F0B3BA !important;
  color: black !important;
  text-align: right;
}
#PivotTable .negative-danger:after {
  content: '▼';
  color: #D0021B !important;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 12;
  padding-left: 1px;
}

#PivotTable .positive-danger {
  background-color: #F0B3BA !important;
  color: black !important;
  text-align: right;
}
#PivotTable .positive-danger:after {
  content: '▲';
  color: #D0021B !important;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 12;
  padding-left: 1px;
}
